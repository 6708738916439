<template>
  <div
    class="mx-auto px-4 sm:px-6 lg:px-8 py-6 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:flex-row lg:space-y-0 lg:space-x-10"
  >
    <!-- Main content container -->
    <div
      class="xl:mx-10 rounded-lg shadow-xl w-full lg:w-full h-full bg-white p-4 sm:p-6 md:p-8 lg:p-10 flex flex-col"
    >
      <div class="w-full h-full flex flex-col">
        <!-- Filter header -->
        <div
          class="w-full border rounded-3xl p-4 sm:p-5 h-auto overflow-x-auto"
        >
          <div
            class="flex flex-wrap justify-between items-center gap-4 sm:gap-6 mb-6"
          >
            <FilterHeader
              title="Test Log"
              :selectedFilter="selectedFilter"
              :selectedTestType="selectedTestType"
              :showFilterDropdown="showFilterDropdown"
              :showTestTypeDropdown="showTestTypeDropdown"
              :average_high="average_high"
              @toggleFilterDropdown="toggleFilterDropdown"
              @toggleTestTypeDropdown="toggleTestTypeDropdown"
              @setFilter="setFilter"
              @setTestType="setTestType"
            />
            <!-- Export section -->
            <div class="flex flex-wrap items-center gap-4">
              <DateRangeCalendar
                v-model="dateRange"
                @update:modelValue="updateDateRange"
                class="w-full sm:w-auto"
              />
              <button
                @click="exportToPDF"
                :disabled="!dateRange.startDate || !dateRange.endDate"
                class="px-4 py-2 bg-ResolutionBlue text-white rounded-lg hover:bg-opacity-90 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Export PDF
              </button>
            </div>
          </div>

          <!-- Data table -->
          <div class="overflow-x-auto h-full flex flex-col py-6">
            <div
              v-if="isPending || isPlaceholderData"
              class="self-center flex justify-center items-center w-6 h-6"
            >
              <SpinnerIcon />
            </div>
            <Table
              v-else-if="isSuccess && paginatedRecords.length > 0"
              :columns="columns"
              :tableData="paginatedRecords"
              class="w-full"
            />
            <ErrorMessage v-else-if="isError && !isPending" />
            <NoDataMessage
              v-else-if="isSuccess && sortedRecords?.length === 0"
              text="You have no tests vitals"
            />
          </div>
          <!-- Pagination -->
          <Pagination
            :current-page="currentPage"
            :number-of-pages="totalPages"
            :is-pending="isPending"
            :fetching-next-page="isPending"
            @prevPage="handlePrevPage"
            @nextPage="handleNextPage"
            @goToPage="handleGoToPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  inject,
  watch,
  h,
  onMounted,
  onBeforeUnmount,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  useQuery,
  keepPreviousData,
  useMutation,
  useQueryClient,
} from "@tanstack/vue-query";
import { mapActions } from "@/hooks/mapStore";
import { push } from "notivue";
import { dateFormatter, timeFormatter } from "@/utils/dateFormatter";
import FilterHeader from "@/components/main/patient/testRecords/FilterHeader.vue";
import SpinnerIcon from "@/components/icons/SpinnerIcon.vue";
import NoDataMessage from "@/components/main/ui/NoDataMessage.vue";
import ErrorMessage from "@/components/main/ui/ErrorMessage.vue";
import Table from "@/components/main/ui/table/Table.vue";
import DateRangeCalendar from "@/components/main/ui/DateRangeCalendar.vue";
import "jspdf-autotable";
import { testTypes, typeMapping } from "@/utils/mockData/testTypes";
import Pagination from "@/components/main/ui/Pagination.vue";
import TestActions from "@/components/main/patient/testCenter/dropdowns/TestActions.vue";
import optionButton from "@/assets/icons/option-button.svg";

const dateRange = ref({
  startDate: null,
  endDate: null,
});

const {
  "user/fetchBloodGlucoseRecords": fetchBloodGlucoseRecords,
  "test/deleteManualTest": deleteManualTest,
  "user/exportPDF": exportPDF,
} = mapActions();

const route = useRoute();
const router = useRouter();
const queryClient = useQueryClient();

const updateDateRange = (newRange) => {
  dateRange.value = newRange;
};

const openEditManualTestModal = inject("openEditManualTestModal");

const currentPage = ref(1);

const average_high = ref(125);

const menuStates = ref({});
const activeMenuId = ref(null);
const menuContainers = ref({});

const selectedTestType = ref(
  testTypes.find((type) => type.type === route.query.testType) || testTypes[0]
);

const showTestTypeDropdown = ref(false);
const selectedFilter = ref("All");
const showFilterDropdown = ref(false);
const sortField = ref(null);
const sortDirection = ref(null);
const columns = ref([]);

const toggleFilterDropdown = () => {
  showFilterDropdown.value = !showFilterDropdown.value;
};

const toggleTestTypeDropdown = () => {
  showTestTypeDropdown.value = !showTestTypeDropdown.value;
};

const setFilter = (filter) => {
  selectedFilter.value = filter;
  showFilterDropdown.value = false;
};

const setTestType = (testType) => {
  selectedTestType.value = testType;
  showTestTypeDropdown.value = false;
  updateColumns(); // Update columns whenever test type changes
  // Update the URL query parameter without reloading the page
  router.replace({
    query: {
      ...route.query,
      testType: testType.type,
    },
  });
};

// Fetch blood glucose records with query
const {
  data: glucoseData,
  isPending,
  isPlaceholderData,
  isSuccess,
  isError,
} = useQuery({
  queryKey: [
    "bloodGlucoseRecords",
    selectedTestType,
    selectedFilter,
    currentPage,
  ],
  queryFn: () =>
    fetchBloodGlucoseRecords({
      test_type: selectedTestType.value.type,
      time_period:
        selectedFilter.value === "All"
          ? null
          : selectedFilter.value.toLowerCase().replace(" ", "_"),
      page: currentPage.value,
    }),
  placeholderData: keepPreviousData,
});

// Computed property for total pages
// Pagination logic
const totalPages = computed(() => {
  const totalResults = glucoseData.value?.total_results || 0;
  return Math.ceil(totalResults / 10); // Defaulting to 10 items per page
});

// Paginated records for the current page
const paginatedRecords = computed(() => glucoseData.value?.results || []);

// Pagination controls
const handlePrevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

const handleNextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
};

const handleGoToPage = (page) => {
  if (page >= 1 && page <= totalPages.value) {
    currentPage.value = page;
  }
};

// Dynamically set columns based on selected test type
const updateColumns = () => {
  const testType = selectedTestType.value.type;

  // Define the "Date" column to come first
  const dateColumn = {
    title: "Date",
    dataIndex: "check_time",
    render: (record) => h("span", null, dateFormatter(record.check_time)),
  };

  const testTypeColumn = {
    title: "Test Type",
    dataIndex: "type",
    render: (record) => {
      const testType = typeMapping[record.type] || "Unknown";
      return h("span", { class: "font-medium text-gray-600" }, testType);
    },
  };

  // Define specific columns based on test type
  const testTypeColumns = {
    glucose: [
      {
        title: "Glucose Level",
        dataIndex: "glucose_level",
        render: (record) => {
          const glucoseClass =
            record.glucose_level < 70
              ? "text-DodgerBlue font-bold"
              : record.glucose_level <= 100
              ? "text-DarkMint font-bold"
              : record.glucose_level <= 125
              ? "text-OrangePeel font-bold"
              : "text-ArtyClickRed font-bold";
          return h(
            "span",
            { class: glucoseClass },
            `${record.glucose_level} ${record.units}`
          );
        },
      },
      {
        title: "Meal Type",
        dataIndex: "meal",
        render: (record) =>
          h(
            "span",
            null,
            record.random_blood_sugar
              ? "Random"
              : record.fasting_blood_sugar
              ? "Fasting"
              : record["2hr_post_meal"]
              ? "2hr_post_meal"
              : ""
          ),
      },
    ],
    heart_rate: [
      {
        title: "Pulse Rate",
        dataIndex: "pulse_rate",
        render: (record) => {
          const pulseClass =
            record.pulse_rate < 70
              ? "text-DodgerBlue font-bold"
              : record.pulse_rate <= 100
              ? "text-DarkMint font-bold"
              : record.pulse_rate <= 125
              ? "text-OrangePeel font-bold"
              : "text-ArtyClickRed font-bold";
          return h("span", { class: pulseClass }, `${record.pulse_rate} bpm`);
        },
      },
      {
        title: "Oxygen Saturation",
        dataIndex: "oxygen_saturation",
        render: (record) =>
          h(
            "span",
            null,
            `${record.oxygen_saturation} ${record.oxygen_saturation_unit}`
          ),
      },
      {
        title: "Perfusion Index",
        dataIndex: "perfusion_index",
        render: (record) =>
          h(
            "span",
            null,
            `${record.perfusion_index} ${record.perfusion_index_unit}`
          ),
      },
    ],
    blood_pressure: [
      {
        title: "Systolic",
        dataIndex: "systolic",
        render: (record) =>
          h("span", null, `${record.systolic} ${record.units}`),
      },
      {
        title: "Diastolic",
        dataIndex: "diastolic",
        render: (record) =>
          h("span", null, `${record.diastolic} ${record.units}`),
      },
      {
        title: "Pulse Rate",
        dataIndex: "pulse_rate",
        render: (record) => {
          const pulseClass =
            record.pulse_rate < 70
              ? "text-DodgerBlue font-bold"
              : record.pulse_rate <= 100
              ? "text-DarkMint font-bold"
              : record.pulse_rate <= 125
              ? "text-OrangePeel font-bold"
              : "text-ArtyClickRed font-bold";
          return h("span", { class: pulseClass }, `${record.pulse_rate} bpm`);
        },
      },
    ],
    weight: [
      {
        title: "Weight",
        dataIndex: "weight",
        render: (record) => h("span", null, `${record.weight} ${record.units}`),
      },
    ],
    temperature: [
      {
        title: "Temperature",
        dataIndex: "temperature",
        render: (record) => {
          const temperatureClass =
            record.temperature < 70
              ? "text-DodgerBlue font-bold"
              : record.temperature <= 100
              ? "text-DarkMint font-bold"
              : record.temperature <= 125
              ? "text-OrangePeel font-bold"
              : "text-ArtyClickRed font-bold";
          return h(
            "span",
            { class: temperatureClass },
            `${record.temperature} ${record.units}`
          );
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (record) => h("span", null, `${record.status}`),
      },
      {
        title: "Description",
        dataIndex: "description",
        render: (record) => h("span", null, `${record.description}`),
      },
    ],
    lung_capacity: [
      {
        title: "FEV1",
        dataIndex: "fev1",
        render: (record) => {
          const value = record.fev1;
          const unit = record.fev1_unit;
          return h(
            "span",
            null,
            value != null && unit != null ? `${value} ${unit}` : "__"
          );
        },
      },
      {
        title: "FEV2",
        dataIndex: "fev2",
        render: (record) => {
          const value = record.fev2;
          const unit = record.fev1_unit;
          return h(
            "span",
            null,
            value != null && unit != null ? `${value} ${unit}` : "__"
          );
        },
      },
      {
        title: "FEV3",
        dataIndex: "fev3",
        render: (record) => {
          const value = record.fev3;
          const unit = record.fev1_unit;
          return h(
            "span",
            null,
            value != null && unit != null ? `${value} ${unit}` : "__"
          );
        },
      },
      {
        title: "PEF",
        dataIndex: "pef",
        render: (record) => {
          const value = record.pef;
          const unit = record.pef_unit;
          return h(
            "span",
            null,
            value != null && unit != null ? `${value} ${unit}` : "__"
          );
        },
      },
    ],
    ecg: [
      {
        title: "Heart Rate",
        dataIndex: "heart_rate",
        render: (record) => {
          const heartClass =
            record.heart_rate < 70
              ? "text-DodgerBlue font-bold"
              : record.heart_rate <= 100
              ? "text-DarkMint font-bold"
              : record.heart_rate <= 125
              ? "text-OrangePeel font-bold"
              : "text-ArtyClickRed font-bold";
          return h(
            "span",
            { class: heartClass },
            `${record.heart_rate} ${record.units}`
          );
        },
      },
      {
    title: "Analysis",
    dataIndex: "analysis",
    render: (record) => {
      const maxLength = 30; // Truncate after 30 characters
      const analysis = record.analysis || "";
      const truncated = analysis.length > maxLength 
        ? `${analysis.slice(0, maxLength)}...` 
        : analysis;

      return h("span", {
        class: "cursor-pointer",
        title: analysis, // Show full text on hover
        innerHTML: truncated
      });
    },
  },
  {
    title: "Note",
    dataIndex: "note",
    render: (record) => {
      const maxLength = 20; // Truncate after 20 characters
      const note = record.note || "";
      const truncated = note.length > maxLength 
        ? `${note.slice(0, maxLength)}...` 
        : note;

      return h("span", {
        class: "cursor-pointer",
        title: note, // Show full text on hover
        innerHTML: truncated
      });
    },
  },
    ],
    malaria: [
      {
        title: "Note",
        dataIndex: "note",
        render: (record) => {
          const maxLength = 20; // maximum length for truncation
          const note = record.malaria_parasite_note || "";
          const truncatedNote =
            note.length > maxLength ? `${note.slice(0, maxLength)}...` : note;

          return h(
            "span",
            {
              class: "cursor-pointer",
              title: note, // Show the full text on hover
            },
            truncatedNote
          );
        },
      },
    ],
    typhoid: [
      {
        title: "Typhi O",
        dataIndex: "typhi_o",
        render: (record) => h("span", null, `${record["Typhi O (TO)"]}`),
      },
      {
        title: "Typhi H",
        dataIndex: "typhi_h",
        render: (record) => h("span", null, `${record["Typhi H (TH)"]}`),
      },
      {
        title: "Paratyphi A,H",
        dataIndex: "paratyphi_a_h",
        render: (record) => h("span", null, `${record["Paratyphi A,H (AH)"]}`),
      },
      {
        title: "Paratyphi B,H",
        dataIndex: "paratyphi_b_h",
        render: (record) => h("span", null, `${record["Paratyphi B,H (BH)"]}`),
      },
    ],

    hepatitis: [
      {
        title: "Result",
        dataIndex: "result",
        render: (record) => h("span", null, `${record.hepatitis_b}`),
      },
    ],
    hiv: [
      {
        title: "Result",
        dataIndex: "result",
        render: (record) => h("span", null, `${record.hiv}`),
      },
    ],
  };

  // Common columns for all test types
  const commonColumns = [
    {
      title: "Time",
      dataIndex: "check_time",
      render: (record) => h("span", null, timeFormatter(record.check_time)),
    },
    {
      title: "Input",
      dataIndex: "input",
    },
    {
      title: "Uploaded By",
      dataIndex: "uploaded_by",
      render: (record) => {
        const uploadedBy = record.attendant?.full_name || "__"; // Render "__" if attendant is null
        return h("span", null, uploadedBy);
      },
    },
    {
      title: "Date Updated",
      dataIndex: "updated_at",
      render: (record) => {
        return h("span", null, dateFormatter(record.updated_at));
      },
    },
    {
      title: "",
      render: (record) => {
        if (record.input === "Manual Input") {
          return h(
            "div",
            {
              ref: (el) => (menuContainers.value[record.id] = el),
              class: "relative",
            },
            [
              h(
                "button",
                {
                  class:
                    "hover:bg-WhiteLilac rotate-90 p-2 text-2xl md:text-3xl font-bold rounded flex justify-center items-center transition-colors duration-300",

                  onClick: (e) => showActionsMenu(e, record.id),
                },
                h("img", {
                  src: optionButton,
                  alt: "three dots",
                })
              ),
              h(TestActions, {
                position: dropdownPosition.value,
                visible: menuStates.value[record.id],
                isDeleting: deleteManualTestMutation.isPending.value,
                onEdit: () => handleEditClick(record),
                onDelete: () => handleDeleteClick(record),
                onClose: () => (menuStates.value[record.id] = false),
              }),
            ]
          );
        }
        return null;
      },
    },
  ];

  // Combine columns with the "Date" column as the first column
  columns.value = [
    dateColumn,
    testTypeColumn,
    ...(testTypeColumns[testType] || []),
    ...commonColumns,
  ];
};

// Click outside handler
const handleClickOutside = (event) => {
  const menuContainer = menuContainers.value[activeMenuId.value];
  if (menuContainer && !menuContainer.contains(event.target)) {
    menuStates.value[activeMenuId.value] = false;
    activeMenuId.value = null;
    document.removeEventListener("click", handleClickOutside);
  }
};

const dropdownPosition = ref({ x: 0, y: 0 });

// Function to toggle the menu visibility
const toggleActionMenu = (id) => {
  Object.keys(menuStates.value).forEach((key) => {
    if (key !== id.toString()) menuStates.value[key] = false;
  });

  const wasOpen = menuStates.value[id];
  menuStates.value[id] = !wasOpen;

  if (!wasOpen) {
    activeMenuId.value = id;
    document.addEventListener("click", handleClickOutside);
  } else {
    activeMenuId.value = null;
    document.removeEventListener("click", handleClickOutside);
  }
};

const showActionsMenu = (event, recordId) => {
  const button = event.target.closest("button");
  if (!button) return;

  const rect = button.getBoundingClientRect();
  const scrollTop = window.scrollY || document.documentElement.scrollTop;
  const scrollLeft = window.scrollX || document.documentElement.scrollLeft;

  dropdownPosition.value = {
    x: rect.left + scrollLeft,
    y: rect.top + rect.height + scrollTop,
  };

  toggleActionMenu(recordId);
};

// Cleanup event listener
onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
});

// Initialize columns on load and update when test type changes
watch(selectedTestType, updateColumns, { immediate: true });

// Define sortedRecords as a computed property that watches glucoseData changes
const sortedRecords = computed(() => {
  // Ensure glucoseData is available before proceeding
  if (!glucoseData.value || !Array.isArray(glucoseData.value.results)) {
    return [];
  }

  const records = glucoseData.value.results;

  // Apply sorting logic
  if (!sortField.value || !sortDirection.value) {
    return records;
  }

  return records.sort((a, b) => {
    let fieldA = a[sortField.value];
    let fieldB = b[sortField.value];

    // Handle string comparisons
    if (typeof fieldA === "string") {
      fieldA = fieldA.toLowerCase();
      fieldB = fieldB.toLowerCase();
    }

    if (sortDirection.value === "asc") {
      return fieldA > fieldB ? 1 : -1;
    } else if (sortDirection.value === "desc") {
      return fieldA < fieldB ? 1 : -1;
    }
  });
});

const exportToPDF = async () => {
  if (!dateRange.value.startDate || !dateRange.value.endDate) {
    return;
  }

  try {
    const payload = {
      patient_id: glucoseData.value.results[0].patient_id,
      date_range: {
        start_date: dateRange.value.startDate.toISOString().split("T")[0],
        end_date: dateRange.value.endDate.toISOString().split("T")[0],
      },
    };
    const response = await exportPDF(payload);
    return response;
  } catch (error) {
    push.error("Unexpected error occurred while exporting the PDF");
  }
};

// Mutation for deleting manual test
const deleteManualTestMutation = useMutation({
  mutationFn: async ({ testType, testTypeId }) => {
    const response = await deleteManualTest({ testType, testTypeId });

    if (!response.success) {
      throw new Error(response.message); // ✅ Ensure an error is only thrown if deletion failed
    }
    return response;
  },
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ["bloodGlucoseRecords"] });
    push.success("Test deleted successfully!");
  },
  onError: (error) => {
    push.error(error.message || "Error deleting the test.");
  },
});

// Open the ManualTestModal with the data of the clicked glucose record
const handleEditClick = (record) => {
  console.log("Editing record:", record);
  toggleActionMenu(record.id);

  const patientId = record.patient_id;
  const testType = typeMapping[record.type];
  const testTypeId = record.id;

  let editingData = {
    check_time: record.check_time,
    input: record.input,
    units: record.units,
    created_at: record.created_at,
    testTypeId: record.testTypeId,
  };

  // Dynamically add fields based on test type
  switch (testType) {
    case "Blood Glucose":
      editingData = {
        ...editingData,
        glucose_level: record.glucose_level,
        fasting_blood_sugar: record.fasting_blood_sugar,
        random_blood_sugar: record.random_blood_sugar,
        ["2hr_post_meal"]: record["2hr_post_meal"],
        testTypeId: record.id,
      };
      break;

    case "Heart Rate":
      editingData = {
        ...editingData,
        oxygen_saturation: record.oxygen_saturation,
        oxygen_saturation_unit: record.oxygen_saturation_unit,
        perfusion_index: record.perfusion_index,
        perfusion_index_unit: record.perfusion_index_unit,
        pulse_rate: record.pulse_rate,
      };
      break;

    case "Blood Pressure":
      editingData = {
        ...editingData,
        systolic: record.systolic,
        diastolic: record.diastolic,
        pulse_rate: record.pulse_rate,
        pad_detected: record.pad_detected,
      };
      break;

    case "Weight":
      editingData = {
        ...editingData,
        weight: record.weight,
      };
      break;

    case "Temperature":
      editingData = {
        ...editingData,
        temperature: record.temperature,
        status: record.status,
        description: record.description,
      };
      break;

    case "Lungs Capacity":
      editingData = {
        ...editingData,
        fev1: record.fev1,
        fev2: record.fev2,
        fev3: record.fev3,
        fev1_unit: record.fev1_unit,
        pef: record.pef,
        pef_unit: record.pef_unit,
      };
      break;

    case "ECG":
      editingData = {
        ...editingData,
        heart_rate: record.heart_rate,
        analysis: record.analysis,
        note: record.note,
      };
      break;

    case "Malaria":
      editingData = {
        ...editingData,
        malaria_parasite_note: record.malaria_parasite_note,
      };
      break;

    case "Widal":
      editingData = {
        ...editingData,
        widal_results: [
          { name: "Typhi O (TO)", value: record["Typhi O (TO)"] },
          { name: "Typhi H (TH)", value: record["Typhi H (TH)"] },
          { name: "Paratyphi A,H (AH)", value: record["Paratyphi A,H (AH)"] },
          { name: "Paratyphi B,H (BH)", value: record["Paratyphi B,H (BH)"] },
        ],
      };
      break;

    case "Hepatitis_B":
      editingData = {
        ...editingData,
        hepatitis_b: record.hepatitis_b,
      };
      break;

    case "HIV":
      editingData = {
        ...editingData,
        hiv: record.hiv,
      };
      break;

    default:
      return;
  }

  openEditManualTestModal(testType, patientId, testTypeId, editingData);

  menuStates.value[record.id] = false;
  activeMenuId.value = null;
};

const handleDeleteClick = async (record) => {
  try {
    await deleteManualTestMutation.mutateAsync({
      testType: typeMapping[record.type],
      testTypeId: record.id,
    });

    setTimeout(() => {
      menuStates.value[record.id] = false;
      activeMenuId.value = null;
    }, 200); // Small delay to allow UI to update
  } catch (error) {
    push.error(error.message || "Error deleting the test.");
  }
};

// Watch for changes in the query parameter and update the selectedTestType
watch(
  () => route.query.testType,
  (newTestType) => {
    const matchingTestType = testTypes.find(
      (type) => type.type === newTestType
    );
    if (matchingTestType) {
      selectedTestType.value = matchingTestType;
    }
  }
);
</script>
