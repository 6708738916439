<template>
  <div
    v-if="isPending"
    class="w-full md:w-[70%] flex justify-center items-center"
  >
    <div class="mx-auto w-6 h-6 mt-20">
      <SpinnerIcon />
    </div>
  </div>

  <ErrorMessage v-else-if="isError" />

  <div v-else-if="isSuccess" class="w-full h-full md:w-[70%]">
    <!-- Family History Section -->
    <div
      class="space-y-2.5"
      :class="{
        'border-b border-b-BlueChalk mb-4': openFamilyHistoryInfo !== true,
        'mb-12': openFamilyHistoryInfo === true,
        'transition-transform duration-700': true,
      }"
    >
      <div
        class="flex items-center justify-between space-x-4 cursor-pointer"
        @click="openFamilyHistoryInfo = !openFamilyHistoryInfo"
      >
        <h4 class="text-MistBlue font-bold text-xs md:text-sm">
          Family and Medical History
        </h4>
        <div>
          <span>
            <img
              :src="Arrow"
              :class="{
                'rotate-180': openFamilyHistoryInfo !== true,
                'transition-transform': true,
                'duration-700': true,
              }"
              alt=""
            />
          </span>
        </div>
      </div>
      <div
        :class="[
          {
            'max-h-0': true,
            'overflow-hidden': true,
            'transition-max-height': true,
            'duration-1000': true,
            'max-h-[500px]': openFamilyHistoryInfo === true,
          },
        ]"
      >
        <div class="w-full flex flex-col gap-3">
          <SelectField
            label="Do you have any of this pre-existing medical conditions"
            v-model="preexisting_conditions"
            :options="preexistingConditionsOptions"
            :placeholder="preexisting_conditions || '--'"
          />

          <SelectField
            label="Do you have any history of pancreatic diseases?"
            v-model="history_of_pancreatic_condition"
            :options="pancreaticHistorysOptions"
            :placeholder="history_of_pancreatic_condition || '--'"
          />
          <SelectField
            label="Are you diabetic? (Select one:)"
            v-model="are_you_diabetic"
            :options="diabeticOptions"
            :placeholder="are_you_diabetic || '--'"
          />
          <SelectField
            label="Have you ever been told you have gestational diabetes?"
            v-model="history_of_gestational_diabetes"
            :options="gestationHistoryOptions"
            :placeholder="history_of_gestational_diabetes || '--'"
          />
          <SelectField
            label="Do you have any relatives with diabetes (a person's parent, sibling, or child.)"
            v-model="history_of_diabetes"
            :options="diabetesHistoryOptions"
            :placeholder="history_of_diabetes || '--'"
          />
          <SelectField
            label="Do you have a family history of cardiovascular disease?"
            v-model="history_of_cardiovascular_disease"
            :options="cardiovascularOptions"
            :placeholder="history_of_cardiovascular_disease || '--'"
          />
        </div>
      </div>
    </div>

    <!-- Lifestyle and Behavioral  Section -->
    <div
      class="space-y-2.5"
      :class="{
        'border-b border-b-BlueChalk mb-4': openLifestyleInfo !== true,
        'mb-12': openLifestyleInfo === true,
        'transition-transform duration-700': true,
      }"
    >
      <div
        class="flex items-center justify-between space-x-4 cursor-pointer"
        @click="openLifestyleInfo = !openLifestyleInfo"
      >
        <h4 class="text-MistBlue font-bold text-xs md:text-sm">
          Lifestyle and Behavioral
        </h4>
        <div>
          <span>
            <img
              :src="Arrow"
              :class="{
                'rotate-180': openLifestyleInfo !== true,
                'transition-transform': true,
                'duration-700': true,
              }"
              alt=""
            />
          </span>
        </div>
      </div>
      <div
        :class="[
          {
            'max-h-0': true,
            'overflow-hidden': true,
            'transition-max-height': true,
            'duration-1000': true,
            'max-h-[700px]': openLifestyleInfo === true,
          },
        ]"
      >
        <div class="w-full flex flex-col gap-3">
          <SelectField
            label="How would you describe your diet"
            v-model="diet"
            :options="dietOptions"
            :placeholder="diet || '--'"
          />
          <SelectField
            label="How often do you exercise?"
            v-model="exercise"
            :options="exerciseOptions"
            :placeholder="exercise || '--'"
          />

          <SelectField
            label="How many hours do you usually sleep per night?"
            v-model="sleep_pattern"
            :options="sleepOptions"
            :placeholder="sleep_pattern || '--'"
          />
          <SelectField
            label="How would you rate your stress level?"
            v-model="stress"
            :options="stressOptions"
            :placeholder="stress || '--'"
          />
          <SelectField
            label="How would you rate your smoking and alcohol Use?"
            v-model="smoke"
            :options="smokeOptions"
            :placeholder="smoke || '--'"
          />
          <SelectField
            label="Are you taking any of these medications?"
            v-model="medication"
            :options="medicationOptions"
            :placeholder="medication || '--'"
          />
          <SelectField
            label="Do you experience heart palpitations or irregular heartbeats? (Select one:)"
            v-model="heart_palpitations"
            :options="heartPalpitationsOptions"
            :placeholder="heart_palpitations || '--'"
          />

          <SelectField
            label="What is your cholesterol level? (Select one:)"
            v-model="cholesterol"
            :options="cholesterolOptions"
            :placeholder="cholesterol || '--'"
          />
        </div>
      </div>
    </div>

    <!-- Psychological parameters Section -->
    <div
      class="space-y-2.5"
      :class="{
        'border-b border-b-BlueChalk mb-4': openPyschologyInfo !== true,
        'mb-12': openPyschologyInfo === true,
        'transition-transform duration-700': true,
      }"
    >
      <div
        class="flex items-center justify-between space-x-4 cursor-pointer"
        @click="openPyschologyInfo = !openPyschologyInfo"
      >
        <h4 class="text-MistBlue font-bold text-xs md:text-sm">
          Psychological Parameters
        </h4>
        <div>
          <span>
            <img
              :src="Arrow"
              :class="{
                'rotate-180': openPyschologyInfo !== true,
                'transition-transform': true,
                'duration-700': true,
              }"
              alt=""
            />
          </span>
        </div>
      </div>
      <div
        :class="[
          {
            'max-h-0': true,
            'overflow-hidden': true,
            'transition-max-height': true,
            'duration-1000': true,
            'max-h-[500px]': openPyschologyInfo === true,
          },
        ]"
      >
        <div class="w-full flex flex-col gap-3">
          <InputField
            label="What is your Systolic Blood Pressure (BP)?"
            v-model="systolicBP"
            :placeholder="systolicBP || 'Enter systolic BP'"
            type="number"
          />
          <InputField
            label="What is your Diastolic Blood Pressure (BP)?"
            v-model="diastolicBP"
            :placeholder="diastolicBP || 'Enter diastolic BP'"
            type="number"
          />
          <InputField
            label="What is your BMI (Body Mass Index)?"
            v-model="heart_rate"
            :placeholder="heart_rate || 'Enter BMI'"
            type="number"
          />
          <InputField
            label="What is your Oxygen Saturation (SpO2) level?"
            v-model="spO2"
            :placeholder="spO2 || 'Enter SpO2 level'"
            type="number"
          />
        </div>
      </div>
    </div>

    <!-- Psychological and emotional health Section -->
    <!-- <div
      class="space-y-2.5"
      :class="{
        'border-b border-b-BlueChalk mb-4': openPyschologyInfo !== true,
        'mb-12': openPyschologyInfo === true,
        'transition-transform duration-700': true,
      }"
    >
      <div
        class="flex items-center justify-between space-x-4 cursor-pointer"
        @click="openPyschologyInfo = !openPyschologyInfo"
      >
        <h4 class="text-MistBlue font-bold text-xs md:text-sm">
          Psychological and emotional health
        </h4>
        <div>
          <span>
            <img
              :src="Arrow"
              :class="{
                'rotate-180': openPyschologyInfo !== true,
                'transition-transform': true,
                'duration-700': true,
              }"
              alt=""
            />
          </span>
        </div>
      </div>
      <div
        :class="[
          {
            'max-h-0': true,
            'overflow-hidden': true,
            'transition-max-height': true,
            'duration-1000': true,
            'max-h-[500px]': openPyschologyInfo === true,
          },
        ]"
      >
        <div class="w-full">

        </div>
      </div>
    </div> -->

    <!-- Environmental and Socioeconomic Section -->
    <div
      class="space-y-2.5"
      :class="{
        'border-b border-b-BlueChalk mb-4': openEnvironmentalInfo !== true,
        'mb-12': openEnvironmentalInfo === true,
        'transition-transform duration-700': true,
      }"
    >
      <div
        class="flex items-center justify-between space-x-4 cursor-pointer"
        @click="openEnvironmentalInfo = !openEnvironmentalInfo"
      >
        <h4 class="text-MistBlue font-bold text-xs md:text-sm">
          Environmental and Socioeconomic Factors
        </h4>
        <div>
          <span>
            <img
              :src="Arrow"
              :class="{
                'rotate-180': openEnvironmentalInfo !== true,
                'transition-transform': true,
                'duration-700': true,
              }"
              alt=""
            />
          </span>
        </div>
      </div>
      <div
        :class="[
          {
            'max-h-0': true,
            'overflow-hidden': true,
            'transition-max-height': true,
            'duration-1000': true,
            'max-h-[500px]': openEnvironmentalInfo === true,
          },
        ]"
      >
        <div class="w-full flex flex-col gap-3">
          <SelectField
            label="Do you have access to healthcare?"
            v-model="access_to_healthcare"
            :options="healthCareOptions"
            :placeholder="access_to_healthcare || '--'"
          />
          <SelectField
            label=". Are you exposed to high levels of pollution in your daily environment? (Select one:)"
            v-model="pollution"
            :options="pollutionOptions"
            :placeholder="pollution || '--'"
          />
          <SelectField
            label="What&#39;s your income level?"
            v-model="income"
            :options="incomeOptions"
            :placeholder="income || '--'"
          />
        </div>
      </div>
    </div>

    <!-- Mental health diagnosis Section -->
    <div
      class="space-y-2.5"
      :class="{
        'border-b border-b-BlueChalk mb-4': openMentalHealthInfo !== true,
        'mb-12': openMentalHealthInfo === true,
        'transition-transform duration-700': true,
      }"
    >
      <div
        class="flex items-center justify-between space-x-4 cursor-pointer"
        @click="openMentalHealthInfo = !openMentalHealthInfo"
      >
        <h4 class="text-MistBlue font-bold text-xs md:text-sm">
          Mental health diagnosis
        </h4>
        <div>
          <span>
            <img
              :src="Arrow"
              :class="{
                'rotate-180': openMentalHealthInfo !== true,
                'transition-transform': true,
                'duration-700': true,
              }"
              alt=""
            />
          </span>
        </div>
      </div>
      <div
        :class="[
          {
            'max-h-0': true,
            'overflow-hidden': true,
            'transition-max-height': true,
            'duration-1000': true,
            'max-h-[500px]': openMentalHealthInfo === true,
          },
        ]"
      >
        <div class="w-full">
          <SelectField
            label="Have you suffered from any mental health issues the over the past 6 months"
            v-model="mental_health"
            :options="mentalHealthOptions"
            :placeholder="mental_health?.toString() || '--'"
          />
        </div>
      </div>
    </div>

    <!-- Demographic and Genetic Factors Section -->
    <div
      class="space-y-2.5"
      :class="{
        'border-b border-b-BlueChalk mb-4': openGeneticInfo !== true,
        'mb-12': openGeneticInfo === true,
        'transition-transform duration-700': true,
      }"
    >
      <div
        class="flex items-center justify-between space-x-4 cursor-pointer"
        @click="openGeneticInfo = !openGeneticInfo"
      >
        <h4 class="text-MistBlue font-bold text-xs md:text-sm">
          Demographic and Genetic Factors
        </h4>
        <div>
          <span>
            <img
              :src="Arrow"
              :class="{
                'rotate-180': openGeneticInfo !== true,
                'transition-transform': true,
                'duration-700': true,
              }"
              alt=""
            />
          </span>
        </div>
      </div>
      <div
        :class="[
          {
            'max-h-0': true,
            'overflow-hidden': true,
            'transition-max-height': true,
            'duration-1000': true,
            'max-h-[500px]': openGeneticInfo === true,
          },
        ]"
      >
        <div class="w-full">
          <SelectField
            label="What is your ethnicity? (Select one:)"
            v-model="ethnicity"
            :options="ethnicityOptions"
            :placeholder="ethnicity?.toString() || '--'"
          />
        </div>
      </div>
    </div>

    <!-- Early Warning Signs Section -->
    <div
      class="space-y-2.5"
      :class="{
        'border-b border-b-BlueChalk mb-4': openEarlySignsInfo !== true,
        'mb-12': openEarlySignsInfo === true,
        'transition-transform duration-700': true,
      }"
    >
      <div
        class="flex items-center justify-between space-x-4 cursor-pointer"
        @click="openEarlySignsInfo = !openEarlySignsInfo"
      >
        <h4 class="text-MistBlue font-bold text-xs md:text-sm">
          Early Warning Signs
        </h4>
        <div>
          <span>
            <img
              :src="Arrow"
              :class="{
                'rotate-180': openEarlySignsInfo !== true,
                'transition-transform': true,
                'duration-700': true,
              }"
              alt=""
            />
          </span>
        </div>
      </div>
      <div
        :class="[
          {
            'max-h-0': true,
            'overflow-hidden': true,
            'transition-max-height': true,
            'duration-1000': true,
            'max-h-[500px]': openEarlySignsInfo === true,
          },
        ]"
      >
        <div class="w-full flex flex-col gap-3">
          <SelectField
            label="Do you experience chest pain or discomfort?"
            v-model="chest_pain"
            :options="chestPainOptions"
            :placeholder="chest_pain || '--'"
          />
          <SelectField
            label="Do you experience shortness of breath during activities"
            v-model="shortness_of_breath"
            :options="shortBreathOptions"
            :placeholder="shortness_of_breath || '--'"
          />
          <SelectField
            label="Do you experience swelling in legs, ankles, or feet?"
            v-model="swelling"
            :options="swellingOptions"
            :placeholder="swelling || '--'"
          />
          <SelectField
            label="Any record of previous diagnosis of cardiovascular condition"
            v-model="previous_cardiac_event"
            :options="prevCardiacOptions"
            :placeholder="previous_cardiac_event || '--'"
          />
        </div>
      </div>
    </div>

    <!-- Advanced Metrics (Optional) Section -->
    <div
      class="space-y-2.5"
      :class="{
        'border-b border-b-BlueChalk mb-4': openAdvanceMetricsInfo !== true,
        'mb-12': openAdvanceMetricsInfo === true,
        'transition-transform duration-700': true,
      }"
    >
      <div
        class="flex items-center justify-between space-x-4 cursor-pointer"
        @click="openAdvanceMetricsInfo = !openAdvanceMetricsInfo"
      >
        <h4 class="text-MistBlue font-bold text-xs md:text-sm">
          Advanced Metrics
        </h4>
        <div>
          <span>
            <img
              :src="Arrow"
              :class="{
                'rotate-180': openAdvanceMetricsInfo !== true,
                'transition-transform': true,
                'duration-700': true,
              }"
              alt=""
            />
          </span>
        </div>
      </div>
      <div
        :class="[
          {
            'max-h-0': true,
            'overflow-hidden': true,
            'transition-max-height': true,
            'duration-1000': true,
            'max-h-[500px]': openAdvanceMetricsInfo === true,
          },
        ]"
      >
        <div class="w-full">
          <SelectField
            label="Do you use wearables for health monitoring?"
            v-model="wearables"
            :options="wearablesOptions"
            :placeholder="wearables || '--'"
          />
        </div>
      </div>
    </div>

    <div class="update-profile-btn-wrapper">
      <BaseButton
        @click="updateMedicalInfo"
        type="submit"
        class="w-48 px-8 py-3 bg-ResolutionBlue text-white rounded-3xl hover:bg-DodgerBlue hover:text-white transition-all duration-300"
      >
        <span v-if="isLoading">
          <LoadingSpinner />
        </span>
        <span v-else>Update profile</span>
      </BaseButton>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from "vue";
import { mapActions } from "@/hooks/mapStore";
import { useQuery, useMutation } from "@tanstack/vue-query";
import { push } from "notivue";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import SpinnerIcon from "@/components/icons/SpinnerIcon.vue";
import ErrorMessage from "@/components/main/ui/ErrorMessage.vue";
import InputField from "../../ui/InputField.vue";
import SelectField from "../../ui/SelectField.vue";
import Arrow from "@/assets/icons/arrow-up-iocn.svg";
import BaseButton from "../../ui/BaseButton.vue";

const {
  "user/updateUserMetricInfo": updateUserMetricInfo,
  "user/fetchPatientProfile": fetchPatientProfile,
} = mapActions();

const openFamilyHistoryInfo = ref(true);
const openLifestyleInfo = ref(true);
const openPhysicalActivityInfo = ref(true);
const openPyschologyInfo = ref(true);
const openEnvironmentalInfo = ref(true);
const openMentalHealthInfo = ref(true);
const openGeneticInfo = ref(true);
const openEarlySignsInfo = ref(true);
const openAdvanceMetricsInfo = ref(true);

// const isLoading = ref(false);

const history_of_cardiovascular_disease = ref("");
const history_of_diabetes = ref("");
const history_of_gestational_diabetes = ref("");
const preexisting_conditions = ref("");
const history_of_pancreatic_condition = ref("");
const are_you_diabetic = ref("");
const heart_palpitations = ref("");
const smoke = ref("");
const medication = ref("");
const cholesterol = ref("");
const alcohol = ref("");
const exercise = ref("");
const sleep_pattern = ref("");
const diet = ref("");
const stress = ref("");
const access_to_healthcare = ref("");
const pollution = ref("");
const income = ref("");
const mental_health = ref("");
const ethnicity = ref("");
const chest_pain = ref("");
const swelling = ref("");
const shortness_of_breath = ref("");
const previous_cardiac_event = ref("");
const wearables = ref("");
const systolicBP = ref("");
const diastolicBP = ref("");
const heart_rate = ref("");
const spO2 = ref("");
const originalMetrics = ref({});

const cardiovascularOptions = [
  "Yes, in immediate family (parents or siblings)",
  "Yes, in extended family (grandparents, uncles, aunts)",
  "No family history",
  "Unsure",
];
const diabetesHistoryOptions = [
  "No family history",
  "One parent with diabetes",
  "Both parents with diabetes",
  "Sibling(s) with diabetes",
  "Other relatives with diabetes",
];
const gestationHistoryOptions = ["Not Applicable", "No", "Yes", "Unsure"];
const preexistingConditionsOptions = [
  "None",
  "Hypertension",
  "High cholesterol/triglycerides",
  "Cardiovascular disease",
  "Multiple pre-diabetes",
];
const dietOptions = [
  "Mostly Healthy(Fruits, Vegetables, Whole Grains)",
  "Moderately Healthy (Mix of healthy and Processed foods)",
  "Mostly Unhealthy (Processed Foods and Sugary Drinks 1–2 times a week)",
  "Very Unhealthy (Processed Foods and Sugary Drinks 3–5 times a week)",
  "Unsure",
];
const pancreaticHistorysOptions = ["No", "Yes", "Never checked"];
const smokeOptions = [
  "Non-smoker, non-drinker",
  "Occasional smoker or drinker",
  "Regular smoker or drinker",
  "Heavy smoker or drinker",
];
const alcoholOptions = ["Occasionally", "Frequent", "No"];
const exerciseOptions = [
  "Sedentary  (Rarely or Never)",
  "Lightly active (1-2x/wk )",
  "Moderately active (3-4x/ Wk,)",
  "Very active (5 -7  X/wk)",
  "Extremely active (athlete-level activity)",
];
const sleepOptions = [
  "Less than 5 hours",
  "5-6 hours",
  "7-8 hours",
  "More than 8 hours",
];
const medicationOptions = [
  "Blood Pressure Medication",
  "Steroids",
  "Antipsychotics",
  "Multiple Medications",
  "None",
];
const heartPalpitationsOptions = [
  "Yes, frequently",
  "Yes, occasionally",
  "No",
  "Unknown",
];
const diabeticOptions = [
  "Yes, diabetic",
  "Yes, pre-diabetic/borderline diabetic",
  "I don’t know",
  "No",
];
const cholesterolOptions = [
  "High",
  "Moderately High",
  "Normal",
  "I don’t know",
];
const stressOptions = ["Low", "Moderate", "High", "Very High"];
const healthCareOptions = ["Yes", "No"];
const pollutionOptions = ["Yes", "No", "Unsure"];
const incomeOptions = ["Low income", "Middle class", "High income"];
const mentalHealthOptions = ["Yes", "No"];
const ethnicityOptions = [
  "African or African-American ",
  "Asian or South Asian",
  "Caucasian/White",
  "Hispanic or Latino",
  "Middle Eastern",
  "Other",
];
const chestPainOptions = ["Yes, frequently", "Yes, occasionally", "No"];
const shortBreathOptions = ["Yes", "No"];
const swellingOptions = ["Yes", "No"];
const prevCardiacOptions = ["Yes", "No"];
const wearablesOptions = ["Yes", "No"];

const blood_pressure = computed(() => {
  if (systolicBP.value && diastolicBP.value) {
    return `${systolicBP.value}/${diastolicBP.value}mmHg`;
  }
  return "";
});

const formattedHeartRate = computed(() => {
  return heart_rate.value ? `${heart_rate.value}bpm` : "";
});

const formattedSpO2 = computed(() => {
  return spO2.value ? `${spO2.value}%` : "";
});

// Use vue-query to fetch the profile data
const {
  data: patientProfileData,
  // isLoading: isProfileLoading,
  isPending,
  isSuccess,
  isError,
  refetch,
} = useQuery({
  queryKey: ["patientProfile"],
  queryFn: fetchPatientProfile,
  enabled: false, // Disable automatic fetching
});

// Function to populate fields
const populateFields = (data) => {
  if (!data?.person?.patient_metrics) return;

  originalMetrics.value = { ...data.person.patient_metrics };

  const metrics = data.person.patient_metrics || {};

  // Extract values from API response
  heart_rate.value =
    (metrics.heart_rate || "").toString().replace("bpm", "") || "";
  spO2.value = (metrics.sp02 || "").toString().replace("%", "") || "";

  if (metrics.blood_pressure && typeof metrics.blood_pressure === "string") {
    const [systolic, diastolic] = metrics.blood_pressure
      .replace("mmHg", "")
      .split("/");
    systolicBP.value = systolic || "";
    diastolicBP.value = diastolic || "";
  } else {
    systolicBP.value = "";
    diastolicBP.value = "";
  }

  // Populate all fields systematically
  const fieldsMapping = {
    history_of_cardiovascular_disease:
      metrics.history_of_cardiovascular_disease,
    history_of_diabetes: metrics.history_of_diabetes,
    history_of_gestational_diabetes: metrics.history_of_gestational_diabetes,
    preexisting_conditions: metrics.preexisting_conditions,
    history_of_pancreatic_condition: metrics.history_of_pancreatic_condition,
    smoke: metrics.smoke,
    are_you_diabetic: metrics.are_you_diabetic,
    heart_palpitations: metrics.heart_palpitations,
    medication: metrics.medication,
    cholesterol: metrics.cholesterol,
    alcohol: metrics.alcohol,
    exercise: metrics.exercise,
    sleep_pattern: metrics.sleep_pattern,
    diet: metrics.diet,
    stress: metrics.stress,
    access_to_healthcare: metrics.access_to_healthcare,
    pollution: metrics.pollution,
    income: metrics.income,
    mental_health: metrics.mental_health,
    ethnicity: metrics.ethnicity,
    chest_pain: metrics.chest_pain,
    shortness_of_breath: metrics.shortness_of_breath,
    swelling: metrics.swelling,
    previous_cardiac_event: metrics.previous_cardiac_event,
    wearables: metrics.wearables,
  };

  // Dynamically update refs
  Object.entries(fieldsMapping).forEach(([refName, value]) => {
    if (value !== undefined && value !== null) {
      const refToUpdate = eval(refName);
      refToUpdate.value = value || "";
    }
  });
};

// Fetch profile data on component mount
onMounted(async () => {
  const { data } = await refetch();
  if (data?.person) {
    populateFields(data);
  }
});

// Watch for changes to patientProfileData
watch(patientProfileData, (newData) => {
  try {
    if (newData?.person) {
      populateFields(newData);
    }
  } catch (error) {
    push.error("Error loading medical data");
  }
});

// Mutation to handle user profile updates
const { mutate: updateProfile, isLoading } = useMutation({
  mutationFn: (updateData) => updateUserMetricInfo(updateData),
  onSuccess: () => {
    push.success("Profile updated successfully.");
  },
  onError: () => {
    push.error("Error updating profile.");
  },
});

const updateMedicalInfo = async () => {
  // Create patient_metrics object with all fields
  const patientMetrics = {
    // ...(patientProfileData.value?.person?.patient_metrics || {}),
    history_of_cardiovascular_disease:
      history_of_cardiovascular_disease.value || null,
    history_of_diabetes: history_of_diabetes.value || null,
    history_of_gestational_diabetes:
      history_of_gestational_diabetes.value || null,
    preexisting_conditions: preexisting_conditions.value || null,
    history_of_pancreatic_condition:
      history_of_pancreatic_condition.value || null,
    are_you_diabetic: are_you_diabetic.value || null,
    heart_palpitations: heart_palpitations.value || null,
    smoke: smoke.value || null,
    medication: medication.value || null,
    cholesterol: cholesterol.value || null,
    alcohol: alcohol.value || null,
    exercise: exercise.value || null,
    sleep_pattern: sleep_pattern.value || null,
    diet: diet.value || null,
    stress: stress.value || null,
    access_to_healthcare: access_to_healthcare.value || null,
    pollution: pollution.value || null,
    income: income.value || null,
    mental_health: mental_health.value || null,
    ethnicity: ethnicity.value || null,
    chest_pain: chest_pain.value || null,
    shortness_of_breath: shortness_of_breath.value || null,
    swelling: swelling.value || null,
    previous_cardiac_event: previous_cardiac_event.value || null,
    wearables: wearables.value || null,
    // Combine systolic & diastolic BP into "120/80mmHg"
    blood_pressure: blood_pressure.value || null,
    heart_rate: formattedHeartRate.value || null,
    sp02: formattedSpO2.value || null,
  };

  // Remove null values
  const filteredMetrics = Object.fromEntries(
    Object.entries(patientMetrics).filter(([_, v]) => v !== null)
  );

  const changedFields = {};
  Object.keys(filteredMetrics).forEach((key) => {
    if (filteredMetrics[key] !== originalMetrics.value[key]) {
      changedFields[key] = filteredMetrics[key];
    }
  });

  // Trigger mutation with the updateData object
  updateProfile(changedFields);
};
</script>

<style>
.update-profile-btn-wrapper {
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 10;
}

@media screen and (max-width: 830px) {
  .update-profile-btn-wrapper {
    position: static;
    top: 40px;
    right: 40px;
    z-index: 10;
  }
}
</style>
