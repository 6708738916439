import glucoseIcon from "../../assets/icons/testTypes/glucose_icon.svg";
import heartIcon from "../../assets/icons/testTypes/heart.svg";
import lungsIcon from "../../assets/icons/testTypes/lungs.svg";
import bloodPressureIcon from "../../assets/icons/testTypes/blood-pressure.svg";
import weightIcon from "../../assets/icons/testTypes/scale.svg";
import heartRateIcon from "../../assets/icons/testTypes/heart-rate.svg";
import thermometer from "../../assets/icons/testTypes/glass-medical-thermometer.svg";
import pulse from "../../assets/icons/testTypes/pulse.svg";
import malaria from "../../assets/icons/testTypes/malaria.svg";
import widal from "../../assets/icons/testTypes/widal.svg";
import hepatitis_b from "../../assets/icons/testTypes/hepatitis-b.svg";
import voluntary_serology from "../../assets/icons/testTypes/voluntary-serology.svg";
import waistCircIcon from "../../assets/icons/testTypes/womens belt.svg";

export const allTestTypes = [
  {
    name: "Glucose",
    image: glucoseIcon,
    isAvailable: true,
  },
  {
    name: "Heart Rate",
    image: heartIcon,
    isAvailable: false,
  },
  {
    name: "Lungs Capacity",
    image: lungsIcon,
    isAvailable: false,
  },
];

export const testTypes = [
  {
    name: 'Glucose',
    type: 'glucose',
    icon: glucoseIcon,
    defaultUnit: 'mg/dL',
    unitOptions: ['mg/dL', 'mmol/L'],
  },

  {
    name: 'Spo2',
    type: 'heart_rate',
    icon: heartRateIcon,
    defaultUnit: 'SpO2',
    unitOptions: ['SpO2'],

  },
  {
    name: 'Blood Pressure',
    type: 'blood_pressure',
    icon: bloodPressureIcon,
    defaultUnit: 'mmHg',
    unitOptions: ['mmHg'],
  },
  {
    name: 'Weight',
    type: 'weight',
    icon: weightIcon,
    defaultUnit: 'kg',
    unitOptions: ['kg', 'lbs'],
  },
  {
    name: 'Temperature',
    type: 'temperature',
    icon: thermometer,
    defaultUnit: '°C',
    unitOptions: ['°C', '°F'],
  },
  {
    name: 'Lungs capacity',
    type: 'lung_capacity',
    icon: lungsIcon,
    defaultUnit: 'L/min',
    unitOptions: ['L/min'],

  },
  {
    name: 'ECG',
    type: 'ecg',
    icon: pulse,
    defaultUnit: 'bpm',
    unitOptions: ['bpm'],
  },
  {
    name: 'Waist Circumference',
    type: 'waist_circumference',
    icon: waistCircIcon,
    defaultUnit: 'cm',
    unitOptions: ['cm'],
  },
  {
    name: 'Malaria',
    type: 'malaria',
    icon: malaria
  },
  {
    name: 'Widal Test',
    type: 'typhoid',
    icon: widal
  },
  {
    name: 'Hepatitis B',
    type: 'hepatitis',
    icon: hepatitis_b
  },
  {
    name: 'Voluntary Serology',
    type: 'hiv',
    icon: voluntary_serology
  }
];

export const typeMapping = {
  PatientGlucoseLevel: "Blood Glucose",
  PatientHeartRate: "Heart Rate",
  PatientBloodPressure: "Blood Pressure",
  PatientWeight: "Weight",
  PatientTemperature: "Temperature",
  PatientLungCapacity: "Lungs Capacity",
  PatientECG: "ECG",
  PatientMalaria: "Malaria",
  PatientWidal: "Widal",
  PatientHepatitis: "Hepatitis_B",
  PatientHiv: "HIV",
};

