<template>
  <aside
    class="fixed lg:sticky top-0 left-0 z-[70] h-screen bg-white text-white transition-all duration-300"
    :class="
      isSidebarOpen
        ? 'w-20 lg:w-[100px] translate-x-0'
        : 'lg:w-[100px] -translate-x-full lg:translate-x-0 overflow-hidden'
    "
  >
    <div
      class="flex flex-col items-center justify-center text-center transition-opacity duration-500"
      :class="isSidebarOpen ? 'opacity-100' : 'opacity-0 lg:opacity-100'"
    >
      <div class="h-[130px] flex items-center">
        <router-link to="">
          <img src="../../../assets/icons/TorbomedPatientIcon.svg" alt="" />
        </router-link>
      </div>
      <div>
        <!-- DASHBOARD -->
        <div v-show="isPatientSettings" class="space-y-1">
          <div class="w-20 lg:w-[100px]">
            <div class="flex items-center justify-between" v-if="showWhiteIcon">
              <span></span>
              <router-link
                to="/patient/dashboard"
                class="bg-ResolutionBlue p-4 rounded-lg"
              >
               <HomeIconFilled />
              </router-link>
              <span>
                <img
                  src="../../../assets/icons/sidebar_border_right.svg"
                  alt=""
                />
              </span>
            </div>
            <div class="flex justify-center" v-else>
              <router-link to="/patient/dashboard" class="p-4 rounded-lg">
                <HomeIcon />
              </router-link>
            </div>
          </div>

          <div class="w-20 lg:w-[100px]">
            <div
              class="flex items-center justify-between"
              v-if="showNotificationWhiteIcon"
            >
              <span></span>
              <router-link
                to="/patient/notifications"
                class="bg-ResolutionBlue p-4 rounded-lg"
              >
                <NotificationIconFilled
                  :notificationCount="notificationCount"
                />
              </router-link>
              <SideBarRightBorderIcon />
            </div>
            <div class="flex justify-center" v-else>
              <router-link to="/patient/notifications" class="p-4 rounded-lg">
                <NotificationIcon :notificationCount="notificationCount" />
              </router-link>
            </div>
          </div>

          <div class="w-20 lg:w-[100px]">
            <div
              class="flex items-center justify-between"
              v-if="showAccountLinkingWhiteIcon"
            >
              <span></span>
              <router-link
                to="/patient/settings/account-linking"
                class="bg-ResolutionBlue p-4 rounded-lg"
              >
                <AccountLinkingIconFilled />
              </router-link>
              <SideBarRightBorderIcon />
            </div>
            <div class="flex justify-center" v-else>
              <router-link
                to="/patient/settings/account-linking"
                class="p-4 rounded-lg"
              >
                <AccountLinkingIcon />
              </router-link>
            </div>
          </div>

          <div class="w-20 lg:w-[100px]">
            <div
              class="flex items-center justify-between"
              v-if="showCalendarWhiteIcon"
            >
              <span></span>
              <router-link
                to="/patient/appointment"
                class="bg-ResolutionBlue p-4 rounded-lg"
              >
                <CalendarIconFilled />
              </router-link>
              <SideBarRightBorderIcon />
            </div>
            <div class="flex justify-center" v-else>
              <router-link to="/patient/appointment" class="p-4 rounded-lg">
               <CalendarIcon />
              </router-link>
            </div>
          </div>

          <div class="w-20 lg:w-[100px]">
            <div
              class="flex items-center justify-between"
              v-if="showSettingWhiteIcon"
            >
              <span></span>
              <router-link
                to="/patient/settings"
                class="bg-ResolutionBlue p-4 rounded-lg"
              >
                <SettingsIconFilled />
              </router-link>
              <SideBarRightBorderIcon />
            </div>
            <div class="flex justify-center" v-else>
              <router-link to="/patient/settings" class="p-4 rounded-lg">
                <SettingsIcon />
              </router-link>
            </div>
          </div>

          <div class="w-20 lg:w-[100px]">
            <div
              class="flex items-center justify-between"
              v-if="showAiWhiteIcon"
            >
              <span></span>
              <router-link
                to="/patient/ai"
                class="bg-ResolutionBlue p-4 rounded-lg"
              >
                <AiIcon />
              </router-link>
              <SideBarRightBorderIcon />
            </div>
            <div class="flex justify-center" v-else>
              <router-link to="/patient/ai" class="p-4 rounded-lg">
                <AiIcon />
              </router-link>
            </div>
          </div>
        </div>

        <div v-show="isAdminSettings">
          <div class="w-[100px]">
            <div class="flex items-center justify-between" v-if="showWhiteIcon">
              <span></span>
              <router-link to="" class="bg-ResolutionBlue p-4 rounded-lg">
                <HomeIconFilled /> 
              </router-link>
              <SideBarRightBorderIcon />
            </div>
            <div class="flex justify-center" v-else>
              <router-link to="/admin/dashboard" class="p-4 rounded-lg">
                <HomeIcon />
              </router-link>
            </div>
          </div>
          <div class="w-[100px]">
            <div
              class="flex items-center justify-between"
              v-if="showAdminWhiteIcon"
            >
              <span></span>
              <router-link
                to="/admin/report-and-statictics"
                class="bg-ResolutionBlue p-4 rounded-lg"
              >
                <SettingsIconFilled />
              </router-link>
              <SideBarRightBorderIcon />
            </div>
            <div class="flex justify-center" v-else>
              <router-link
                to="/admin/report-and-statictics"
                class="p-4 rounded-lg"
              >
              <SettingsIcon />
              </router-link>
            </div>
          </div>
          <div class="w-[100px]">
            <div
              class="flex items-center justify-between"
              v-if="showSettingWhiteIcon"
            >
              <span></span>
              <router-link to="" class="bg-ResolutionBlue p-4 rounded-lg">
                <PatientIconFilled />
              </router-link>
              <SideBarRightBorderIcon />
            </div>
            <div class="flex justify-center" v-else>
              <router-link to="" class="p-4 rounded-lg">
                <PatientIcon />
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="absolute bottom-12">
        <BaseButton @click="showLogoutModal = true">
          <LogoutIcon />
        </BaseButton>

        <LogoutModal :show="showLogoutModal" @close="showLogoutModal = false" />
      </div>
    </div>
  </aside>
</template>

<script setup>
import { computed, inject, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import NotificationIcon from "@/components/icons/NotificationIcon.vue";
import NotificationIconFilled from "@/components/icons/NotificationIconFilled.vue";
import AccountLinkingIcon from "@/components/icons/AccountLinkingIcon.vue";
import AccountLinkingIconFilled from "@/components/icons/AccountLinkingIconFilled.vue";
import LogoutModal from "../ui/modals/LogoutModal.vue";
import BaseButton from "@/components/main/ui/BaseButton.vue"
import AiIcon from "@/components/icons/AiIcon.vue"
import HomeIcon from "@/components/icons/HomeIcon.vue"
import HomeIconFilled from "@/components/icons/HomeIconFilled.vue"
import CalendarIcon from "@/components/icons/CalendarIcon.vue"
import CalendarIconFilled from "@/components/icons/CalendarIconFilled.vue"
import SettingsIcon from "@/components/icons/SettingsIcon.vue"
import SettingsIconFilled from "@/components/icons/SettingsIconFilled.vue"
import PatientIcon from "@/components/icons/PatientIcon.vue"
import PatientIconFilled from "@/components/icons/PatientIconFilled.vue"
import SideBarRightBorderIcon from "@/components/icons/SideBarRightBorderIcon.vue"
import LogoutIcon from "@/components/icons/LogoutIcon.vue"

    const route = useRoute();
    const store = useStore();
    
    const isSidebarOpen = inject("isSidebarOpen");
    const showLogoutModal = ref(false);
    const notificationCount = computed(() => store.getters["notifications/getNotificationCounts"].allNotifications);
    // Computed properties for icon visibility based on routes
    const showWhiteIcon = computed(() => 
      ["/patient/dashboard", "/patient/blood-glucose", "/admin/dashboard"].includes(route.path)
    );
    const showNotificationWhiteIcon = computed(() => 
      route.path.startsWith("/patient/notifications")
    );
    const showAccountLinkingWhiteIcon = computed(() => 
      route.path.startsWith("/patient/settings/account-linking")
    );
    const showCalendarWhiteIcon = computed(() => 
      route.path.startsWith("/patient/appointment")
    );
    const showSettingWhiteIcon = computed(() => 
      route.path === "/patient/settings" ||
      route.path.startsWith("/admin/settings") ||
      route.path.startsWith("/admin/vital-details")
    );
    const showAdminWhiteIcon = computed(() => 
      route.path.startsWith("/admin/report-and-statictics")
    );
    const isPatientSettings = computed(() => 
      route.path.startsWith("/patient")
    );
    const isAdminSettings = computed(() => 
      route.path.startsWith("/admin")
    );

    const showAiWhiteIcon = computed(() => 
       route.path.startsWith("/patient/ai")
    )
</script>