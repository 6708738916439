<template>
  <div class="flex flex-col h-full gap-4">
    <div class="w-full h-[30%] flex items-start gap-3">
      <div
        class="w-[50%] h-[30%] bg-ResolutionBlue text-white flex flex-col items-start gap-3.5 border rounded-xl p-5"
      >
        <div
          class="w-[80px] h-[80px] flex items-center justify-center font-bold rounded-full border-[6px] border-Glaucous p-4"
        >
          {{
            getPatientProgressScore === null
              ? "--"
              : getPatientProgressScore?.overall_progress !== null
              ? getPatientProgressScore?.overall_progress
              : "--"
          }}%
        </div>

        <div class="space-y-2">
          <p class="text-sm md:text-base font-bold">Your Progress</p>
          <p class="text-BlueChalk text-xs font-normal">
            Monthly Progress Report
          </p>
        </div>
      </div>

      <router-link
        to="/patient/dashboard/health-score"
        class="w-[50%] h-[30%] p-[18px] flex flex-col items-start gap-4 bg-fadedResolutionBlue text-DavyGrey rounded-xl cursor-pointer"
      >
        <div
          class="relative flex items-center justify-center w-[80px] h-[80px]"
        >
          <!-- Background Circle -->
          <svg class="absolute w-full h-full" viewBox="0 0 36 36">
            <circle
              cx="18"
              cy="18"
              r="15.9155"
              fill="none"
              stroke="#D3D3D3"
              stroke-width="4"
            />
            <!-- Progress Circle -->
            <circle
              cx="18"
              cy="18"
              r="15.9155"
              fill="none"
              stroke="#1E3A8A"
              stroke-width="4"
              stroke-linecap="round"
              stroke-dasharray="100 100"
              :style="{
                strokeDasharray: '100 100',
                strokeDashoffset: `${100 - progressStroke}`,
                transition: 'stroke-dashoffset 0.8s ease-in-out',
              }"
              transform="rotate(-90 18 18)"
            />
          </svg>

          <!-- Text Display -->
          <span class="absolute text-EgyptianBlue font-bold text-base">
            {{ healthScore ?? "--" }}
          </span>
        </div>

        <div class="space-y-2">
          <p class="text-sm md:text-base font-bold">Health Score</p>
          <p class="text-xs font-normal text-SkyGrey">Health Report</p>
        </div>
      </router-link>
    </div>

    <!-- Patient Bio Section -->
    <div
      class="w-full h-[70%] flex flex-col gap-3 py-2.5 px-4 bg-crystalBlue rounded-xl"
    >
      <h3 class="text-DarkJungle text-base font-bold">Patient Bio</h3>

      <div class="grid grid-cols-2 md:grid-cols-3 gap-4">
        <div
          v-for="(item, index) in patientBio"
          :key="index"
          class="w-full flex items-center gap-2"
        >
          <div
            class="w-8 h-8 flex items-center justify-center bg-lightFrost rounded-full"
          >
            <img :src="item.icon" :alt="item.label + ' Icon'" />
          </div>

          <div class="flex flex-col gap-1">
            <p class="text-[10px] text-OlsoGrey font-medium">
              {{ item.label }}
            </p>
            <h3 class="text-DarkJungleGreen text-sm font-bold">
              {{ item.value || "--" }}
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { mapGetters, mapActions } from "@/hooks/mapStore";
import { useQuery } from "@tanstack/vue-query";
import BloodGroupIcon from "../../../../assets/icons/patientBio/bg-icon.svg";
import GenotypeIcon from "@/assets/icons/patientBio/red and blue dna.svg";
import AgeIcon from "@/assets/icons/patientBio/ageIcon.svg";
import HeightIcon from "@/assets/icons/patientBio/heightIcon.svg";
import WeightIcon from "@/assets/icons/patientBio/weight.svg";
import BmiIcon from "@/assets/icons/patientBio/bmiIcon.svg";

const {
  "user/getPatientProgressScore": getPatientProgressScore,
  "user/getPatientHealthScore": getPatientHealthScore,
} = mapGetters();

const {
  "user/fetchPatientProfile": fetchPatientProfile,
  "user/fetchPatientHealthScore": fetchPatientHealthScore,
} = mapActions();

// Fetching the patient health score
const {
  data: healthScoreData,
  isLoading: isHealthScoreLoading,
  isError: isHealthScoreError,
} = useQuery({
  queryKey: ["patientHealthScore"],
  queryFn: fetchPatientHealthScore,
  onSuccess: (data) => {},
});

//  Ensure healthScore updates reactively
const healthScore = computed(() => {
  return healthScoreData.value?.data?.Total_Health_Score ?? 0;
});
const { data: getPatientProfile } = useQuery({
  queryKey: ["patient-profile"],
  queryFn: fetchPatientProfile,
});

// Convert health score to percentage for SVG stroke
const progressStroke = computed(() => {
  let score = getPatientHealthScore?.value?.data?.Total_Health_Score;
  return score !== null && !isNaN(score) ? (score / 100) * 100 : 0; // Ensure it's in range 0-100
});

// Compute patient bio dynamically
const patientBio = computed(() => {
  const person = getPatientProfile?.value?.person || {};
  return [
    {
      label: "Blood Group",
      value: person.blood_group || "--",
      icon: BloodGroupIcon,
    },
    {
      label: "Genotype",
      value: person.genotype || "--",
      icon: GenotypeIcon,
    },
    {
      label: "Age",
      value: person.age || "--",
      icon: AgeIcon,
    },
    {
      label: "Height",
      value: person.height
        ? `${person.height} ${person.height_units || "cm"}`
        : "--",
      icon: HeightIcon,
    },
    {
      label: "Weight",
      value: person.weight
        ? `${person.weight} ${person.weight_units || "kg"}`
        : "--",
      icon: WeightIcon,
    },
    {
      label: "BMI",
      value: person.bmi || "--",
      icon: BmiIcon,
    },
  ];
});
</script>
