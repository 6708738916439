<template>
  <div
    v-if="isPending"
    class="w-full md:w-[70%] flex justify-center items-center"
  >
    <div class="mx-auto w-6 h-6 mt-20">
      <SpinnerIcon />
    </div>
  </div>

  <ErrorMessage v-else-if="isError" />

  <div v-else-if="isSuccess" class="w-full md:w-[70%] no-scrollbar">
    <!-- Basic Information Section -->
    <div
      class="space-y-2.5"
      :class="{
        'border-b border-b-BlueChalk mb-4': openBasiInfo !== true,
        'mb-12': openBasiInfo === true,
        'transition-transform duration-700': true,
      }"
    >
      <div
        class="flex items-center justify-between space-x-4 cursor-pointer"
        @click="openBasiInfo = !openBasiInfo"
      >
        <h4 class="text-MistBlue font-bold text-xs md:text-sm">Basic</h4>
        <div>
          <span>
            <img
              :src="Arrow"
              :class="{
                'rotate-180': openBasiInfo !== true,
                'transition-transform': true,
                'duration-700': true,
              }"
              alt=""
            />
          </span>
        </div>
      </div>
      <div
        :class="[
          {
            'max-h-0': true,
            'overflow-hidden': true,
            'transition-max-height': true,
            'duration-1000': true,
            'max-h-[800px]': openBasiInfo === true,
          },
        ]"
      >
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <SelectField
            label="Title"
            v-model="title"
            :options="nameTitles"
            :placeholder="title || '--'"
          />
          <InputField
            label="First name"
            v-model="firstName"
            :placeholder="firstName || '--'"
          />
          <InputField
            label="Last Name"
            v-model="lastName"
            :placeholder="lastName || '--'"
          />
          <div
            class="cursor-not-allowed bg-WhiteLilac flex flex-col w-full pt-4 pb-3 border-b-2 border-BlueChalk"
          >
            <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">User name</p>
            <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
              {{ userName || "--" }}
            </p>
          </div>
          <InputField
            label="Date of Birth"
            v-model="dob"
            type="date"
            :placeholder="dob || '--'"
            :max="currentDate"
          />
          <div
            class="cursor-not-allowed flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
          >
            <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">Age</p>
            <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
              {{ age || "--" }}
            </p>
          </div>
          <SelectField
            label="Gender"
            v-model="gender"
            :placeholder="gender"
            :options="['Male', 'Female']"
          />
          <SelectField
            label="Marital Status"
            v-model="maritalStatus"
            :placeholder="maritalStatus"
            :options="['Single', 'Married', 'Divorced']"
          />
          <InputField
            label="Weight"
            v-model="weightString"
            :placeholder="weightString || '--'"
          />
          <InputField
            label="Height"
            v-model="heightString"
            :placeholder="heightString || '--'"
          />
          <InputField
            label="Waist Circumference"
            v-model="waist_circumference"
            :placeholder="waist_circumference || '--'"
          />

        </div>
      </div>
    </div>

    <!-- Residential Address Section -->
    <div
      class="space-y-2.5"
      :class="{
        'border-b border-b-BlueChalk mb-4': openResidentialAddress !== true,
        'mb-12': openResidentialAddress === true,
        'transition-transform duration-700': true,
      }"
    >
      <div
        class="flex items-center justify-between space-x-4 cursor-pointer"
        @click="openResidentialAddress = !openResidentialAddress"
      >
        <h4 class="text-MistBlue font-bold text-xs md:text-sm">
          Residential Address 1
        </h4>
        <div>
          <span>
            <img
              :src="Arrow"
              :class="{
                'rotate-180': openResidentialAddress !== true,
                'transition-transform': true,
                'duration-700': true,
              }"
              alt=""
            />
          </span>
        </div>
      </div>
      <div
        :class="[
          {
            'max-h-0': true,
            'overflow-hidden': true,
            'transition-max-height': true,
            'duration-1000': true,
            'max-h-[500px]': openResidentialAddress === true,
          },
        ]"
      >
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div
            class="cursor-not-allowed flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
          >
            <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">Address</p>
            <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
              {{ address || "--" }}
            </p>
          </div>
          <SelectField
            label="Country"
            v-model="country"
            :options="countries"
            :placeholder="country || '--'"
          />
          <SelectField
            label="State"
            v-model="state"
            :options="states"
            :placeholder="state"
          />
          <InputField label="City" v-model="city" :placeholder="city || '--'" />
          <InputField
            label="Zip Code"
            v-model="zipCode"
            :placeholder="zipCode || '--'"
          />
        </div>
      </div>
    </div>

    <!-- Contact Section -->
    <div
      class="space-y-2.5"
      :class="{
        'border-b border-b-BlueChalk mb-4': openContact !== true,
        'mb-12': openContact === true,
        'transition-transform duration-700': true,
      }"
    >
      <div
        class="flex items-center justify-between space-x-4 cursor-pointer"
        @click="openContact = !openContact"
      >
        <h4 class="text-MistBlue font-bold text-xs md:text-sm">Contact</h4>
        <div>
          <span>
            <img
              :src="Arrow"
              :class="{
                'rotate-180': openContact !== true,
                'transition-transform': true,
                'duration-700': true,
              }"
              alt=""
            />
          </span>
        </div>
      </div>
      <div
        :class="[
          {
            'max-h-0': true,
            'overflow-hidden': true,
            'transition-max-height': true,
            'duration-1000': true,
            'max-h-[500px]': openContact === true,
          },
        ]"
      >
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <InputField
            label="Phone Number (Primary)"
            type="number"
            v-model="phone"
            :placeholder="phone || '--'"
          />
          <InputField
            label="Phone Number (Secondary)"
            type="number"
            v-model="secondaryPhone"
            :placeholder="secondaryPhone || '--'"
          />
          <div
            class="cursor-not-allowed flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
          >
            <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
              Email (Primary)
            </p>
            <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
              {{ email || "--" }}
            </p>
          </div>
          <InputField
            label="Email (Secondary)"
            v-model="secondaryEmail"
            type="email"
            :placeholder="secondaryEmail || '--'"
          />
        </div>
      </div>
    </div>

    <!-- Preferred Language Section -->
    <div
      class="space-y-2.5"
      :class="{
        'border-b border-b-BlueChalk mb-4': openSpokenLanguage !== true,
        'mb-12': openSpokenLanguage === true,
        'transition-transform duration-700': true,
      }"
    >
      <div
        class="flex items-center justify-between space-x-4 cursor-pointer"
        @click="openSpokenLanguage = !openSpokenLanguage"
      >
        <h4 class="text-MistBlue font-bold text-xs md:text-sm">
          Preferred language of communication
        </h4>
        <div>
          <span>
            <img
              :src="Arrow"
              :class="{
                'rotate-180': openSpokenLanguage !== true,
                'transition-transform': true,
                'duration-700': true,
              }"
              alt=""
            />
          </span>
        </div>
      </div>
      <div
        :class="[
          {
            'max-h-0': true,
            'overflow-hidden': true,
            'transition-max-height': true,
            'duration-1000': true,
            'max-h-[500px]': openSpokenLanguage === true,
          },
        ]"
      >
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <SelectField
            label="Language"
            v-model="language"
            :options="languages"
            :placeholder="language || '--'"
          />
          <SelectField
            label="Language Proficiency"
            v-model="languageProficiency"
            :options="proficiencyLevels"
            :placeholder="languageProficiency"
          />
          <SelectField
            label="Language 2 (optional)"
            v-model="secondLanguage"
            :options="languages"
            :placeholder="secondLanguage"
          />
          <SelectField
            label="Language 2 Proficiency"
            v-model="secondLanguageProficiency"
            :options="proficiencyLevels"
            :placeholder="secondLanguageProficiency"
          />
          <SelectField
            label="Language 3 (optional)"
            v-model="thirdLanguage"
            :options="languages"
            :placeholder="thirdLanguage"
          />
          <SelectField
            label="Language 3 Proficiency"
            v-model="thirdLanguageProficiency"
            :options="proficiencyLevels"
            :placeholder="thirdLanguageProficiency"
          />
        </div>
      </div>
    </div>

    <!-- Emergency Contact Section -->
    <div
      class="space-y-2.5"
      :class="{
        'border-b border-b-BlueChalk mb-4': openEmergencyContact !== true,
        'mb-12': openEmergencyContact === true,
        'transition-transform duration-700': true,
      }"
    >
      <div
        class="flex items-center justify-between space-x-4 cursor-pointer"
        @click="openEmergencyContact = !openEmergencyContact"
      >
        <h4 class="text-MistBlue font-bold text-xs md:text-sm">
          Emergency Contact (1)
        </h4>
        <div>
          <span>
            <img
              :src="Arrow"
              :class="{
                'rotate-180': openEmergencyContact !== true,
                'transition-transform': true,
                'duration-700': true,
              }"
              alt=""
            />
          </span>
        </div>
      </div>
      <div
        :class="[
          {
            'max-h-0': true,
            'overflow-hidden': true,
            'transition-max-height': true,
            'duration-1000': true,
            'max-h-[500px]': openEmergencyContact === true,
          },
        ]"
      >
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <InputField
            label="Name"
            v-model="emergencyContactName"
            :placeholder="emergencyContactName || '--'"
          />
          <template v-if="emergencyContactRelationship === 'Other'">
            <InputField
              label="Relationship"
              v-model="emergencyContactRelationshipOther"
              placeholder="Please specify"
            />
          </template>
          <template v-else>
            <SelectField
              label="Relationship"
              v-model="emergencyContactRelationship"
              :options="[
                'Brother',
                'Sister',
                'Mother',
                'Father',
                'Neighbour',
                'Friend',
                'Husband',
                'Wife',
                'Other',
              ]"
              :placeholder="emergencyContactRelationship"
            />
          </template>
          <InputField
            label="Phone Number (Primary)"
            type="number"
            v-model="emergencyContactPhone"
            :placeholder="emergencyContactPhone"
          />
          <InputField
            label="Phone Number (Secondary)"
            type="number"
            v-model="emergencyContactPhoneSecondary"
            :placeholder="emergencyContactPhoneSecondary"
          />
          <SelectField
            label="Language of Communication"
            v-model="emergencyContactLanguage"
            :options="languages"
            :placeholder="emergencyContactLanguage"
          />
        </div>
      </div>
    </div>

    <!-- Second Emergency Contact Section -->
    <div
      class="space-y-2.5"
      :class="{
        'border-b border-b-BlueChalk mb-4': openEmergencyContact_2 !== true,
        'mb-12': openEmergencyContact_2 === true,
        'transition-transform duration-700': true,
      }"
    >
      <div
        class="flex items-center justify-between space-x-4 cursor-pointer"
        @click="openEmergencyContact_2 = !openEmergencyContact_2"
      >
        <h4 class="text-MistBlue font-bold text-xs md:text-sm">
          Emergency Contact (2)
        </h4>
        <div>
          <span>
            <img
              :src="Arrow"
              :class="{
                'rotate-180': openEmergencyContact_2 !== true,
                'transition-transform': true,
                'duration-700': true,
              }"
              alt=""
            />
          </span>
        </div>
      </div>
      <div
        :class="[
          {
            'max-h-0': true,
            'overflow-hidden': true,
            'transition-max-height': true,
            'duration-1000': true,
            'max-h-[500px]': openEmergencyContact_2 === true,
          },
        ]"
      >
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <InputField
            label="Name"
            v-model="secondEmergencyContactName"
            :placeholder="secondEmergencyContactName || '--'"
          />
          <template v-if="secondEmergencyContactRelationship === 'Other'">
            <InputField
              label="Relationship"
              v-model="secondEmergencyContactRelationshipOther"
              placeholder="Please specify"
            />
          </template>
          <template v-else>
            <SelectField
              label="Relationship"
              v-model="secondEmergencyContactRelationship"
              :options="[
                'Brother',
                'Sister',
                'Mother',
                'Father',
                'Neighbour',
                'Friend',
                'Husband',
                'Wife',
                'Other',
              ]"
              :placeholder="secondEmergencyContactRelationship"
            />
          </template>
          <InputField
            label="Phone Number (Primary)"
            type="number"
            v-model="secondEmergencyContactPhone"
            :placeholder="secondEmergencyContactPhone"
          />
          <InputField
            label="Phone Number (Secondary)"
            type="number"
            v-model="secondEmergencyContactPhoneSecondary"
            :placeholder="secondEmergencyContactPhoneSecondary"
          />
          <SelectField
            label="Language of Communication"
            v-model="secondEmergencyContactLanguage"
            :placeholder="secondEmergencyContactLanguage"
            :options="languages"
          />
        </div>
      </div>
    </div>

    <div class="update-profile-btn-wrapper overflow-hidden">
      <BaseButton
        @click="updatePersonalInfo"
        type="submit"
        class="w-48 px-8 py-3 bg-ResolutionBlue text-white rounded-3xl hover:bg-DodgerBlue hover:text-white transition-all duration-300"
      >
        <span v-if="isLoading">
          <LoadingSpinner />
        </span>
        <span v-else>Update profile</span>
      </BaseButton>

      <!-- ProfileCompletion Component -->
      <ProfileCompletion
        :percentageComplete="patientProfileData.person.percentage_complete || 0"
        :profileCompletion="patientProfileData.person.profile_completion || {}"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from "vue";
import { mapActions } from "@/hooks/mapStore";
import { useQuery, useMutation } from "@tanstack/vue-query";
import { push } from "notivue";
import { states } from "@/utils/mockData/states";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import SpinnerIcon from "@/components/icons/SpinnerIcon.vue";
import ErrorMessage from "@/components/main/ui/ErrorMessage.vue";
import InputField from "../../ui/InputField.vue";
import SelectField from "../../ui/SelectField.vue";
import Arrow from "@/assets/icons/arrow-up-iocn.svg";
import BaseButton from "../../ui/BaseButton.vue";
import ProfileCompletion from "./ProfileCompletion.vue";

const {
  "user/updateUserPersonalInfo": updateUserPersonalInfo,
  "user/fetchPatientProfile": fetchPatientProfile,
} = mapActions();

const openBasiInfo = ref(true);
const openResidentialAddress = ref(true);
const openContact = ref(true);
const openSpokenLanguage = ref(true);
const openEmergencyContact = ref(true);
const openEmergencyContact_2 = ref(true);

const fetchedData = ref({
  percentage_complete: 0,
  profile_completion: {},
});

const title = ref("");
const firstName = ref("");
const lastName = ref("");
const userName = ref("");
const dob = ref("");
const age = ref("");
const gender = ref("");
const maritalStatus = ref("");
const weight = ref(0);
const weight_units = ref("");
const waist_circumference = ref("");
const height = ref(0);
const height_units = ref("");

const address = ref("");
const country = ref("");
const countries = ref([]);
const state = ref("");
const city = ref("");
const zipCode = ref("");
const phone = ref("");
const email = ref("");
const secondaryPhone = ref("");
const secondaryEmail = ref("");
const language = ref("");
const languageProficiency = ref("");
const secondLanguage = ref("");
const secondLanguageProficiency = ref("");
const thirdLanguage = ref("");
const thirdLanguageProficiency = ref("");
const emergencyContactName = ref("");
const emergencyContactRelationship = ref("");
const emergencyContactRelationshipOther = ref("");
const emergencyContactPhone = ref("");
const emergencyContactPhoneSecondary = ref("");
const emergencyContactLanguage = ref("");
const secondEmergencyContactName = ref("");
const secondEmergencyContactRelationship = ref("");
const secondEmergencyContactRelationshipOther = ref("");
const secondEmergencyContactPhone = ref("");
const secondEmergencyContactPhoneSecondary = ref("");
const secondEmergencyContactLanguage = ref("");

const nameTitles = [
  "Mr.",
  "Mrs.",
  "Miss",
  "Ms.",
  "Sir",
  "Madam",
  "Master",
  "Dr.",
  "Prof.",
  "Rev.",
  "Capt.",
  "Sgt.",
  "Lord",
  "Dame",
];

const currentDate = ref(new Date().toISOString().slice(0, 10));
const weightString = computed({
  get: () => {
    if (!weight.value && weight.value !== 0) return ''
    return `${weight.value}.  ${weight_units.value}`.trim()
  },
  set: (val) => {
    // Extract numeric value and preserve the unit
    const numericValue = parseFloat(val) || 0
    weight.value = numericValue
  },
})
const heightString = computed({
  get: () => {
    if (!height.value && height.value !== 0) return ''
    return `${height.value}.  ${height_units.value}`.trim()
  },
  set: (val) => {
    // Extract numeric value and preserve the unit
    const numericValue = parseFloat(val) || 0
    height.value = numericValue
  },
})


const languages = ["English", "Yoruba", "Igbo", "Hausa", "Pidgin"];
const proficiencyLevels = ["Beginner", "Intermediate", "Fluent", "Native"];

// Use vue-query to fetch the profile data
const {
  data: patientProfileData,
  // isLoading: isProfileLoading,
  isPending,
  isSuccess,
  isError,
  refetch,
} = useQuery({
  queryKey: ["patientProfile"],
  queryFn: fetchPatientProfile,
  enabled: false, // Disable automatic fetching
});

// Function to populate fields
const populateFields = (data) => {
  if (!data || !data.person) {
    return;
  }

  const person = data.person;
  // Populate all fields systematically
  const fieldsMapping = {
    title: person.title,
    firstName: person.first_name,
    lastName: person.last_name,
    userName: person.username,
    dob: person.date_of_birth,
    age: person.age,
    gender: person.gender,
    maritalStatus: person.marital_status,
    waist_circumference: person.patient_metrics?.waist_circumference || "",
    weight: person.weight || 0,
    weight_units: person.weight_units,
    height: person.height || 0,
    height_units: person.height_units,
    address: person.address,
    country: person.country,
    state: person.state,
    city: person.city,
    zipCode: person.zip_code,
    phone: person.phone,
    email: person.email,
    secondaryPhone: person.secondary_phone,
    secondaryEmail: person.secondary_email,
    language: person.language,
    languageProficiency: person.language_proficiency,
    secondLanguage: person.second_language,
    secondLanguageProficiency: person.second_language_proficiency,
    thirdLanguage: person.third_language,
    thirdLanguageProficiency: person.third_language_proficiency,
    emergencyContactName: person.emergency_contact_name,
    emergencyContactRelationship: person.emergency_contact_relationship,
    emergencyContactPhone: person.emergency_contact_phone,
    emergencyContactPhoneSecondary: person.emergency_contact_phone_secondary,
    emergencyContactLanguage: person.emergency_contact_language,
    secondEmergencyContactName: person.second_emergency_contact_name,
    secondEmergencyContactRelationship:
      person.second_emergency_contact_relationship,
    secondEmergencyContactPhone: person.second_emergency_contact_phone,
    secondEmergencyContactPhoneSecondary:
      person.second_emergency_contact_phone_secondary,
    secondEmergencyContactLanguage: person.second_emergency_contact_language,
  };

  // Dynamically update refs
  Object.entries(fieldsMapping).forEach(([refName, value]) => {
    if (value !== undefined && value !== null) {
      const refToUpdate = eval(refName);
      refToUpdate.value = value || "";
    }
  });
};

// Fetch profile data on component mount
onMounted(async () => {
  const { data } = await refetch();
  if (data?.person) {
    populateFields(data);
  }
});

// Watch for changes to patientProfileData
watch(patientProfileData, (newData) => {
  if (newData && newData.person) {
    populateFields(newData);
  }
});

const fetchCountries = async () => {
  try {
    const response = await fetch("https://restcountries.com/v3.1/all");
    if (!response.ok) {
      throw new Error("Failed to fetch countries");
    }
    const data = await response.json();
    countries.value = data.map((country) => country.name.common).sort();
  } catch (error) {
    push.error(error);
  }
};

onMounted(() => {
  fetchCountries();
});

// Mutation to handle user profile updates
const { mutate: updateProfile, isLoading } = useMutation({
  mutationFn: (updateData) => updateUserPersonalInfo(updateData),
  onSuccess: () => {
    push.success("Profile updated successfully.");
  },
  onError: () => {
    push.error("Error updating profile.");
  },
});

const updatePersonalInfo = async () => {
  // Collect only fields with values to allow partial updates
  const updateData = {
    title: title.value || undefined,
    first_name: firstName.value || undefined,
    last_name: lastName.value || undefined,
    date_of_birth: dob.value || undefined,
    phone: phone.value || undefined,
    secondary_phone: secondaryPhone.value || undefined,
    secondary_email: secondaryEmail.value || undefined,
    gender: gender.value || undefined,
    country: country.value || undefined,
    state: state.value || undefined,
    city: city.value || undefined,
    zip_code: zipCode.value || undefined,
    marital_status: maritalStatus.value || undefined,
    waist_circumference: waist_circumference.value || undefined || undefined,
    weight: weight.value || undefined || undefined,
    height: height.value || undefined || undefined,
    language: language.value || undefined,
    language_proficiency: languageProficiency.value || undefined,
    second_language: secondLanguage.value || undefined,
    second_language_proficiency: secondLanguageProficiency.value || undefined,
    third_language: thirdLanguage.value || undefined,
    third_language_proficiency: thirdLanguageProficiency.value || undefined,
    emergency_contact_name: emergencyContactName.value || undefined,
    emergency_contact_relationship:
      emergencyContactRelationship.value === "Other"
        ? emergencyContactRelationshipOther.value
        : emergencyContactRelationship.value,
    emergency_contact_phone: emergencyContactPhone.value || undefined,
    emergency_contact_phone_secondary:
      emergencyContactPhoneSecondary.value || undefined,
    emergency_contact_language: emergencyContactLanguage.value || undefined,
    second_emergency_contact_name:
      secondEmergencyContactName.value || undefined,
    second_emergency_contact_relationship:
      secondEmergencyContactRelationship.value === "Other"
        ? secondEmergencyContactRelationshipOther.value
        : secondEmergencyContactRelationship.value,
    second_emergency_contact_phone:
      secondEmergencyContactPhone.value || undefined,
    second_emergency_contact_phone_secondary:
      secondEmergencyContactPhoneSecondary.value || undefined,
    second_emergency_contact_language:
      secondEmergencyContactLanguage.value || undefined,
  };

  // Trigger mutation with the updateData object
  updateProfile(updateData);
};

// Watch for changes to the emergencyContactRelationship to reset the other input field
watch(emergencyContactRelationship, (newVal) => {
  if (newVal !== "Other") {
    emergencyContactRelationshipOther.value = "";
  }
});

watch(secondEmergencyContactRelationship, (newVal) => {
  if (newVal !== "Other") {
    secondEmergencyContactRelationshipOther.value = "";
  }
});

onMounted(() => {
  // Mock fetched data
  fetchedData.value = {
    percentage_complete: 55, // Overall progress
    profile_completion: {
      Basic: 66.67,
      Contact: 100,
      "Emergency Contact 1": 80,
      "Emergency Contact 2": 80,
      "Medical Information": 42.86,
      "Preferred Hospital": 0,
      "Residential Address 1": 80,
      "Residential Address 2": 0,
      "Spoken Languages": 100,
    },
  };
});
</script>

<style>
.update-profile-btn-wrapper {
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media screen and (max-width: 830px) {
  .update-profile-btn-wrapper {
    position: static;
    top: 40px;
    right: 40px;
    z-index: 10;
  }
}
</style>
